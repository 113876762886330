import PropTypes from "prop-types";

import Helmet from "react-helmet";
import Header from "./header";
import AOS from "aos";
import "@sass/main.scss";

import React, { Component } from "react";

class Layout extends Component {
  componentDidMount() {
    AOS.init({
      disable: "mobile"
    });
  }
  render() {
    const children = this.props.children;
    return (
      <div className="flex flex-col font-sans min-h-screen">
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/agn5wiq.css" />
        </Helmet>
        <Header />

        <main className="page-main flex flex-col flex-1 md:justify-center mx-auto px-0 py-8 md:px-8 w-full">
          {children}
        </main>
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
