import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import Logo from "@components/common/logo";
import Headroom from "react-headroom";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Headroom>
      <header id="main-header" className="bg-orange text-teal-100 text-2xl">
        <div className="flex flex-wrap items-center justify-between max-w-6xl mx-auto p-4 md:p-8">
          <div className="text-left">
            <div className="logo text-teal-100">
              <Link to="/" title="Home">
                <Logo className="fill-current" />
              </Link>
            </div>
          </div>
          <button
            className="block md:hidden flex items-center"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className={`${isExpanded ? `active` : null} ham hamRotate ham1`}
              viewBox="0 0 100 100"
              width="40"
            >
              <path
                className="line top"
                d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
              />
              <path className="line middle" d="m 30,50 h 40" />
              <path
                className="line bottom"
                d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
              />
            </svg>
          </button>

          <nav
            className={`${
              isExpanded ? `block` : `hidden`
            } md:block main-nav md:items-center text-right md:text-left text-xl md:mx-auto w-full md:w-auto`}
          >
            <Link
              className="block md:inline-block mt-4 md:mt-0 lg:mx-8 mx-4 no-underline"
              to="/"
            >
              Home
            </Link>

            {/* <Link
              className="block md:inline-block mt-4 md:mt-0 lg:mx-8 mx-4 no-underline"
              to="/menu/"
            >
              Menu
            </Link> */}
            <Link
              className="block md:inline-block mt-4 md:mt-0 lg:mx-8 mx-4 no-underline"
              to="/about/"
            >
              About us
            </Link>
            <Link
              className="block md:inline-block mt-4 md:mt-0 lg:mx-8 mx-4  no-underline"
              to="/contact/"
            >
              Contact
            </Link>

            <a
              className="block md:hidden mt-4 md:mt-0 mx-4 no-underline"
              href="https://book.easytablebooking.com/book/?id=51d29&lang=auto"
            >
              Reservations
            </a>
          </nav>
          <nav className="hidden md:inline-block text-right">
            <span className="bg-teal-100 inline-block py-1 uppercase rounded px-3">
              <span className="purple text-lg">
                <a
                  href="https://book.easytablebooking.com/book/?id=51d29&lang=auto"
                  title="Book"
                  target="_blank"
                  rel="noopener"
                >
                  Reservations
                </a>
              </span>
            </span>
          </nav>
        </div>
      </header>
    </Headroom>
  );
}

export default Header;
