import React from "react";

const Logo = ({
  style = {},

  width = "100%",
  className = "fill-current",
  viewBox = "0 0 164.5 69.9"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M31.8 28.5c-1.5-1.2-3-2.1-4.5-2.7-1.5-.6-3-.9-4.4-.9-1.8 0-3.3.4-4.4 1.3s-1.7 2-1.7 3.3c0 .9.3 1.7.9 2.3.6.6 1.3 1.1 2.2 1.6.9.5 2 .8 3.1 1.2 1.2.3 2.3.7 3.4 1.1 4.5 1.5 7.9 3.5 10 6.1 2.1 2.5 3.2 5.8 3.2 9.9 0 2.7-.5 5.2-1.4 7.5-.9 2.2-2.3 4.1-4.1 5.7-1.8 1.6-4 2.8-6.5 3.7-2.6.9-5.5 1.3-8.8 1.3-6.8 0-13.1-2-18.8-6l6-11.2c2.1 1.8 4.1 3.2 6.2 4.1 2 .9 4.1 1.4 6 1.4 2.3 0 4-.5 5.1-1.6 1.1-1 1.7-2.2 1.7-3.6 0-.8-.1-1.5-.4-2.1-.3-.6-.8-1.1-1.4-1.6-.7-.5-1.5-1-2.6-1.4-1.1-.4-2.4-.9-3.9-1.4-1.8-.6-3.6-1.2-5.3-1.9-1.7-.7-3.3-1.6-4.6-2.7-1.4-1.1-2.4-2.6-3.3-4.3-.8-1.7-1.2-3.9-1.2-6.6 0-2.7.4-5.1 1.3-7.2.9-2.2 2.1-4 3.7-5.5s3.5-2.7 5.8-3.6c2.3-.9 4.9-1.3 7.7-1.3 2.7 0 5.4.4 8.3 1.1 2.9.7 5.7 1.8 8.3 3.2l-5.6 10.8zM45.9 41.6c0-4 .7-7.7 2.2-11.1 1.5-3.4 3.5-6.4 6.1-9 2.6-2.6 5.7-4.6 9.3-6 3.6-1.4 7.6-2.2 12-2.2 4.3 0 8.3.7 11.9 2.2 3.6 1.4 6.8 3.4 9.4 6s4.7 5.6 6.1 9c1.5 3.4 2.2 7.1 2.2 11.1s-.7 7.7-2.2 11.1c-1.5 3.4-3.5 6.4-6.1 9-2.6 2.6-5.8 4.6-9.4 6-3.6 1.4-7.6 2.2-11.9 2.2-4.4 0-8.4-.7-12-2.2-3.6-1.4-6.7-3.4-9.3-6-2.6-2.6-4.6-5.6-6.1-9-1.5-3.4-2.2-7.1-2.2-11.1zm14.6 0c0 2.1.4 4.1 1.2 5.9.8 1.8 1.9 3.4 3.3 4.7 1.4 1.3 3 2.4 4.8 3.1 1.8.7 3.8 1.1 5.8 1.1s4-.4 5.8-1.1c1.8-.7 3.4-1.8 4.8-3.1s2.5-2.9 3.3-4.7c.8-1.8 1.2-3.8 1.2-5.9s-.4-4.1-1.2-5.9c-.8-1.8-1.9-3.4-3.3-4.7-1.4-1.3-3-2.4-4.8-3.1-1.8-.7-3.8-1.1-5.8-1.1s-4 .4-5.8 1.1c-1.8.7-3.4 1.8-4.8 3.1s-2.5 2.9-3.3 4.7c-.8 1.8-1.2 3.8-1.2 5.9zM128 14.8v53.6h-13.9V14.8H128z" />
    <g>
      <path d="M152.4 13.3h-10.5l3.2-3.3c.8-.9 1.5-1.6 2-2.2.5-.6.8-1.1 1.1-1.6.2-.4.4-.8.4-1 .1-.3.1-.5.1-.7 0-.2 0-.4-.1-.6-.1-.2-.2-.3-.3-.5-.1-.1-.3-.3-.5-.3-.1-.1-.3-.1-.5-.1-.5 0-.8.1-1.1.4-.3.3-.4.7-.4 1.1 0 .1 0 .3.1.6h-3.7c0-.8.1-1.4.4-2.1.2-.6.6-1.2 1-1.6.4-.4 1-.8 1.6-1 .6-.2 1.3-.4 2-.4s1.4.1 2 .3c.6.2 1.1.6 1.6 1 .4.4.8.9 1 1.5.3.6.4 1.2.4 1.9s-.2 1.4-.5 2c-.3.6-.8 1.2-1.5 1.9l-2.2 2h4.2v2.7zM155.6 13.3l3.7-4.2h-.1c-.3.2-.6.3-.8.4-.2 0-.4.1-.6.1-.6 0-1.2-.1-1.7-.4-.5-.2-1-.6-1.4-1-.4-.4-.7-.9-.9-1.5-.2-.6-.3-1.2-.3-1.8 0-.7.1-1.3.4-1.9.3-.6.6-1.1 1.1-1.5.5-.4 1.1-.8 1.7-1 .7-.3 1.4-.4 2.3-.4.9 0 1.6.1 2.3.4.7.3 1.3.6 1.7 1 .5.4.8.9 1.1 1.5.3.6.4 1.2.4 1.9s-.2 1.5-.5 2.4c-.3.9-.8 1.7-1.5 2.5l-3 3.7h-3.9zm5.6-8.5c0-.3-.1-.6-.2-.8-.1-.3-.3-.5-.5-.7-.2-.2-.4-.4-.7-.5-.3-.1-.5-.2-.8-.2s-.6.1-.8.2c-.3.1-.5.3-.7.5-.2.2-.4.4-.5.7-.1.3-.2.5-.2.8 0 .3.1.6.2.8.1.3.3.5.5.7s.4.4.7.5c.3.1.5.2.8.2s.6-.1.8-.2c.3-.1.5-.3.7-.5s.3-.4.5-.7c.1-.2.2-.5.2-.8z" />
    </g>
  </svg>
);

export default Logo;
